import '@/assets/app.scss'
import 'vue3-toastify/dist/index.css'

import { VueQueryPlugin } from '@tanstack/vue-query'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { createApp } from 'vue'
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify'

import { i18n, useI18n } from '@/plugins/i18n'

import App from './App.vue'
import router from './router'

const app = createApp(App)

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
app.use(VueQueryPlugin)
app.use(pinia)
app.use(Vue3Toastify, {
  autoClose: 5000,
} as ToastContainerOptions)

app.use(router)
useI18n(app, i18n, 'nl')

app.mount('#app')

export function getApp() {
  return app
}
