<script setup lang="ts">
import { ALabel, ALanguageInput } from '@atabix/vue-ui'
import { openErrorDialog } from '@atabix/vue-ui/src/lib/dialog/utils'
import { type HTMLAttributes, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'

import { User } from '@/models'
import { getCurrentLocale, type Locale, SUPPORT_LOCALES } from '@/plugins/i18n'
import { useAuthStore } from '@/stores/auth'
import { useSettingsStore } from '@/stores/settings'

const properties = defineProps<{
  label?: string
  ariaLabel?: string
  class?: HTMLAttributes['class']
}>()

const language = ref<Locale>(getCurrentLocale())
const auth = useAuthStore()

const { t } = useI18n()

async function updateLanguage(value: Locale) {
  if (!auth.user) {
    useSettingsStore().setLocale(value)
    return
  }
  try {
    const user = await auth.user.update({ language: value })
    auth.setUser(new User().hydrate({ ...auth.user, language: user.language }))
  } catch (error) {
    console.error(error)
    openErrorDialog({ title: t('unexpected error occurred'), description: t('error changing {item}', { item: t('language', 1) }) })
  }
}

watch(
  () => language.value,
  (value) => {
    updateLanguage(value)
  },
)
</script>

<template>
  <div class="language-select">
    <ALabel v-if="label">{{ label }}</ALabel>
    <ALanguageInput v-model="language" :aria-label :class="properties.class" hide-flag :locales="[...SUPPORT_LOCALES]" />
  </div>
</template>

<style scoped>
.language-select {
  @apply flex flex-col gap-y-3;
}
</style>
