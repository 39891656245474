import { Str } from '@atabix/core/src/Str'
import { HttpStatusCode } from 'axios'

import type { AuthDriver } from '@/auth'
import { isLocal } from '@/environments.ts'
import { User } from '@/models'
import { getCurrentLocale } from '@/plugins/i18n'
import { useAuthStore } from '@/stores/auth'
import { useSettingsStore } from '@/stores/settings'
import { useTokenStore } from '@/stores/token'

export interface RegisterPayload {
  type: { id: string }
  fields: {
    id: string
    value: unknown
  }[]
}

type TokenDriver = AuthDriver & {
  url(path: string): string
  consumeTokenResponse(response: OAuth2TokenResponse): Promise<void>
  setRefreshInterval(): Promise<void>
  refresh(minValiditySeconds: number): Promise<void>
}

const TokenDriver: TokenDriver = {
  url(path: string = '/'): string {
    const settings = useSettingsStore()
    const url = settings.get('api.crm') || ''

    return `${Str.trim(url, '/')}${path}`
  },

  async login(username: string, password: string): Promise<void> {
    const auth = useAuthStore()
    const response = await fetch(this.url('/customers/token'), {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Language': getCurrentLocale(),
      }),
      body: JSON.stringify({
        grant_type: 'password',
        username,
        password,
      }),
    })

    const json = await response.json()
    if (!response.ok) throw new OAuth2TokenError(json.error, json.error_description, json.message)

    const data: OAuth2TokenResponse = json
    await this.consumeTokenResponse(data)
    await this.session()

    auth.setInitalized(true)
  },

  async proxyLogin(proxyId: string): Promise<void> {
    const auth = useAuthStore()

    const response = await fetch(this.url('/customers/proxy-token'), {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Language': getCurrentLocale(),
        Authorization: useTokenStore().access ? `Bearer ${useTokenStore().access}` : '',
      }),
      body: JSON.stringify({
        proxy_id: proxyId,
      }),
    })

    const json = await response.json()
    if (!response.ok) throw new OAuth2TokenError(json.error, json.error_description, json.message)

    const data: OAuth2TokenResponse = json
    await this.consumeTokenResponse(data)
    await this.session()

    auth.setInitalized(true)
  },

  async logout(): Promise<void> {
    const token = useTokenStore()
    const auth = useAuthStore()
    const settings = useSettingsStore()

    if (!auth.isInitalized) return

    auth.setLoading(true)
    auth.setHasLoggedOut(true)

    // Refresh the teapot to check the status of the application and reload images.
    await settings.teapot()

    auth.clear()
    token.clear()

    auth.setInitalized(false)
  },

  async session(): Promise<User | null> {
    const auth = useAuthStore()
    const token = useTokenStore()

    if (!token.access || !auth.user) {
      auth.setLoading(false)
      auth.setInitalized(false)
    }

    if (token.access && token.refresh) {
      this.setRefreshInterval()
    }

    if (token.access && (!auth.user || auth.isSelectingProxy)) {
      try {
        const user = await User.current()

        if (user) {
          auth.setUser(user)
          await useSettingsStore().refresh()
        }
      } catch {
        // Do nothing
      }
    }

    const user = auth.getUser()

    if (!user) {
      await this.logout()

      // eslint-disable-next-line unicorn/no-null
      return null
    }

    auth.setInitalized(true)
    return user
  },

  async setRefreshInterval() {
    const token = useTokenStore()
    if (token.isTimerRunning) return

    await useTokenStore().setInterval(async () => {
      this.refresh(120)
    }, 10_000)
  },

  async refresh(minValiditySeconds): Promise<void> {
    const token = useTokenStore()
    const isExpired = token.isExpired(minValiditySeconds)

    // Refresh the token if it is will expire within the next amount of seconds.
    if (!isExpired) return
    if (isLocal) console.log('[ATABASE] Token Expired! Refreshing...')

    const response = await fetch(this.url('/customers/token'), {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Language': getCurrentLocale(),
      }),
      body: JSON.stringify({
        grant_type: 'refresh_token',
        refresh_token: token.refresh,
      }),
    })

    const data: OAuth2TokenResponse = await response.json()
    await this.consumeTokenResponse(data)
  },

  async consumeTokenResponse(response: OAuth2TokenResponse): Promise<void> {
    const token = useTokenStore()

    token.setAccess(response.access_token)
    token.setRefresh(response.refresh_token)
    token.setTimeSkew()

    this.setRefreshInterval()
  },

  async forgotPassword(username: string): Promise<void> {
    const response = await fetch(this.url('/customers/forgot-password'), {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Language': getCurrentLocale(),
      }),
      body: JSON.stringify({ username }),
    })

    if (!response.ok) {
      throw await this.handleAuthFormError(response)
    }
  },

  async resetPassword(username: string, password: string, token: string): Promise<void> {
    const response = await fetch(this.url('/customers/reset-password'), {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Language': getCurrentLocale(),
      }),
      body: JSON.stringify({ username, password, token, password_confirmation: password }),
    })

    if (!response.ok) {
      throw await this.handleAuthFormError(response)
    }

    console.log(response.json())
  },

  async register(payload: RegisterPayload): Promise<void> {
    const response = await fetch(this.url('/customers/register'), {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'Accept-Language': getCurrentLocale(),
      }),
      body: JSON.stringify(payload),
    })

    if (!response.ok) {
      throw await this.handleAuthFormError(response)
    }

    console.log(response.json())
  },

  async handleAuthFormError(response: Response): Promise<Error> {
    const json = await response.json()
    if (response.status === HttpStatusCode.UnprocessableEntity && typeof json.errors === 'object') {
      return new UnprocessableAuthError(json.message, json.errors)
    }
    return new Error(json.message)
  },
}

export default TokenDriver

export type OAuth2TokenResponse = {
  token_type: 'Bearer'
  expires_in: number
  access_token: string
  refresh_token: string
}

export class OAuth2TokenError extends Error {
  constructor(
    public error: string,
    public error_description: string,
    public message: string,
  ) {
    super(message)
    this.name = 'OAuth2TokenError'
  }
}

export class UnprocessableAuthError extends Error {
  constructor(
    public message: string,
    public errors: Record<string, string[]>,
  ) {
    super(message)
    this.name = 'UnprocessableAuthError'
  }
}
