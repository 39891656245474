import TokenDriver from '@/auth/TokenDriver'
import { getCurrentLocale } from '@/plugins/i18n'
import { useTokenStore } from '@/stores'

export interface Proxy {
  id: string
  name: string
}

export async function getProxies(): Promise<Proxy[] | null> {
  const response = await fetch(TokenDriver.url('/customers/proxies'), {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'Accept-Language': getCurrentLocale(),
      Authorization: useTokenStore().access ? `Bearer ${useTokenStore().access}` : '',
    }),
  })

  const json = await response.json()
  return json
}
