/**
 * Copied from Vueuse
 * https://github.com/vueuse/vueuse/blob/main/packages/core/useIdle/index.ts
 *
 * Some tweaks were made to the original code so that the timing is correct when tabbed out.
 * The browser will throttle the events when the tab is not active, so we need to use the timestamp when we focus again.
 */
import {
  type ConfigurableEventFilter,
  type ConfigurableWindow,
  createFilterWrapper,
  defaultWindow,
  throttleFilter,
  timestamp,
  useEventListener,
  type WindowEventName,
} from '@vueuse/core'
import type { Ref } from 'vue'
import { ref } from 'vue'

const defaultEvents: WindowEventName[] = ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel']
const oneMinute = 60_000

export interface UseIdleOptions extends ConfigurableWindow, ConfigurableEventFilter {
  /**
   * Event names that listen to for detected user activity
   *
   * @default ['mousemove', 'mousedown', 'resize', 'keydown', 'touchstart', 'wheel']
   */
  events?: WindowEventName[]
  /**
   * Initial state of the ref idle
   *
   * @default false
   */
  initialState?: boolean
}

export interface UseIdleReturn {
  idle: Ref<boolean>
  lastActive: Ref<number>
  reset: () => void
}

/**
 * Tracks whether the user is being inactive.
 *
 * @see https://vueuse.org/useIdle
 * @param timeout default to 1 minute
 * @param options IdleOptions
 */
export function useIdle(timeout: number = oneMinute, options: UseIdleOptions = {}): UseIdleReturn {
  const { initialState = false, events = defaultEvents, window = defaultWindow, eventFilter = throttleFilter(50) } = options
  const idle = ref(initialState)
  const lastActive = ref(timestamp())

  let timer: NodeJS.Timeout | number

  const reset = () => {
    idle.value = false
    startTimeout(timeout)
  }

  const startTimeout = (duration: number) => {
    clearTimeout(timer)
    timer = setTimeout(() => {
      idle.value = true
    }, duration)
  }

  const onEvent = createFilterWrapper(eventFilter, () => {
    lastActive.value = timestamp()
    reset()
  })

  if (window) {
    const document = window.document
    for (const event of events) useEventListener(window, event, onEvent, { passive: true })

    // Here is the fix
    useEventListener(document, 'visibilitychange', () => {
      if (document.hidden || idle.value) {
        return
      }
      const timeLeft = timeout - (timestamp() - lastActive.value)
      if (timeLeft <= 0) {
        idle.value = true
      } else {
        startTimeout(timeLeft)
      }
    })

    reset()
  }

  return {
    idle,
    lastActive,
    reset,
  }
}
