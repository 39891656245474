<script lang="ts" setup>
import { ACard, List, type ListItemInterface } from '@atabix/vue-ui'
import { LoginIcon } from 'mdi-vue3'
import { computed, markRaw } from 'vue'
import { useI18n } from 'vue-i18n'

import type { Proxy } from '@/auth/Proxies'
import { useAuthStore } from '@/stores'

interface ProxyLoginListProperties {
  disabled?: boolean
  proxies?: Proxy[]
}

const properties = defineProps<ProxyLoginListProperties>()

interface ProxyLoginListEmits {
  (event: 'proxy-login', proxy: Proxy): void
}
const emit = defineEmits<ProxyLoginListEmits>()

const auth = useAuthStore()

const { t } = useI18n({
  messages: {
    en: {
      'as myself': 'As myself',
    },
    nl: {
      'as myself': 'Als mijzelf',
    },
    de: {
      'as myself': 'Als ich selbst',
    },
  },
})

const self = computed((): Proxy => ({ id: auth.user?.id ?? '-', name: t('as myself') }))

const items = computed((): ListItemInterface[] =>
  [self.value, ...(properties.proxies ?? [])].map(
    (proxy): ListItemInterface => ({
      id: proxy.id,
      title: proxy.name,
      icon: markRaw(LoginIcon),
      iconPosition: 'right',
      disabled: properties.disabled,
      click: () => onClickLogin(proxy),
    }),
  ),
)

function onClickLogin(proxy: Proxy) {
  emit('proxy-login', proxy)
}
</script>

<template>
  <div class="proxy-login-list">
    <ACard :class="{ 'atabix-card--disabled': disabled }" :collapse="true">
      <List :collapsed="false" :items="items" />
    </ACard>
  </div>
</template>

<style lang="scss" scoped>
.proxy-login-list {
  max-height: 600px;
  overflow-y: auto;
}
.atabix-card--disabled {
  @apply bg-background-disabled;
  @apply cursor-not-allowed;
}

:deep(.atabix-list) {
  @apply px-4;
  @apply gap-0;

  > div {
    @apply border-b;
    @apply border-border;

    &:last-child {
      @apply border-none;
    }
  }

  .atabix-list-item {
    @apply my-4;
  }

  .atabix-list-item__item-container {
    @apply w-full;
    @apply justify-between;
    @apply px-2;
  }
}
</style>
