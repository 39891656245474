import type { NotificationType } from '@/enums/NotiicationType'
import { Crm } from '@/plugins/http'
import type { Locale } from '@/plugins/i18n'

// #region Enums
// #endregion

// #region Interfaces

export type UserIncludes = 'fields' | 'emails' | 'phones' | 'addresses'

export type UserMetaIncludes = never

export type UserSorts = never

export type UserFilters = {
  current?: boolean
  is_enabled?: boolean
}

export type UserCreatePayload = {
  first_name?: string
  last_name?: string
  email?: string
}

export type UserUpdatePayload = {
  avatar?: string
  first_name?: string
  infix_name?: string
  last_name?: string
  email?: string
  is_enabled?: boolean
  language?: string
  password?: string
  password_current?: string
  notifications?: Record<NotificationType, boolean> | null
}

export type UserResetPasswordPayload = {
  email: string
  token: string
  password: string
  password_confirmation: string
}

export type UserPutPayload = {
  never: never
}

// #endregion

// #region Constants
// #endregion

export class User extends Crm<UserFilters, UserCreatePayload, UserUpdatePayload, UserPutPayload, UserIncludes, UserMetaIncludes, UserSorts> {
  // #region Class properties

  protected $endpoint = '/customers/profile'
  protected $primaryKey: keyof this = 'id'
  protected $fillable: (keyof this)[] = []

  public static includes: UserIncludes[] = ['fields', 'emails', 'phones', 'addresses']

  // #endregion

  // #region General attributes

  public id!: string
  public name!: string
  public email!: string | null
  public phone!: string | null
  public address!: Adress | null
  public language!: Locale
  public notifications!: Record<NotificationType, boolean> | null

  // #endregion

  // #region Default attributes

  public fields?: Field[] | null

  // #endregion

  // #region Included attributes

  // #endregion

  // #region Relationship methods
  // #endregion

  // #region Endpoint methods
  // #endregion

  // #region Class methods

  // #region Class properties
  // #endregion

  // #region General attributes
  // #endregion

  // #region Default attributes
  // #endregion

  // #region Included attributes
  // #endregion

  // #region Relationship methods
  // #endregion

  // #region Endpoint methods
  // #endregion

  // #region Class methods

  public static current(): Promise<User | null> {
    try {
      return new User().withoutErrorResolver().filter({ current: true }).all() as unknown as Promise<User | null>
    } catch {
      // eslint-disable-next-line unicorn/no-null
      return Promise.resolve(null)
    }
  }

  // #endregion

  // #region Async methods (api calls only)

  // #endregion

  // #region Attribute getters & Setters
  // #endregion

  // #region Getters & Setters

  // #endregion
}

export type AdressLabel = {
  id: string
  created_at?: string | null
  name?: string | null
  type?: string | null
  updated_at?: string | null
}

export type Adress = {
  id: string
  city?: string | null
  country_code?: string | null
  is_primary?: boolean | null
  label?: AdressLabel | null
  number_addition?: string | null
  number_letter?: string | null
  number?: string | null
  organization?: string | null
  post_code?: string | null
  state?: string | null
  street?: string | null
}

export type Email = {
  id: string
  created_at?: string | null
  is_primary?: boolean | null
  label?: string | null
  value?: string | null
}

export type FieldGroup = {
  id: string
  name?: string | null
  sort_order?: number | null
}

export type ProxySource = {
  id: string
  name: string
  email: string | null
  phone: string | null
}

export type FieldOption = never

export type FieldType =
  | 'checkbox'
  | 'currency'
  | 'date'
  | 'number'
  | 'radio'
  | 'select'
  | 'switch'
  | 'text_area'
  | 'text'
  | 'url'
  | 'custom_list'
  | 'entity'

export type Field = {
  id: string
  is_editable?: boolean | null
  label?: string | null
  options?: FieldOption[] | null
  type?: FieldType | null
  value?: string[] | string | null
  human?: string[] | string | null
}

export type Phone = {
  id: string
  created_at?: string | null
  is_primary?: boolean | null
  label?: string | null
  value?: string | null
}
