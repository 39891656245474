import type { NavigationGuardWithThis } from 'vue-router'

import { ApplicationState } from '@/enums/ApplicationState'
import { useAppStore } from '@/stores/app'

const ReadyMiddleware: NavigationGuardWithThis<undefined> = async function (to, from, next) {
  const app = useAppStore()
  if (app.isReady) return

  if (app.isInitialized) {
    setTimeout(() => app.setState(ApplicationState.READY), 200)
  }
}

export default ReadyMiddleware
