import type { NavigationGuardWithThis } from 'vue-router'

import { useAuthDriver } from '@/auth'

const KeycloakMiddleware: NavigationGuardWithThis<undefined> = async function (to, from, next) {
  const driver = useAuthDriver()
  await driver.driver.session()

  return true
}

export default KeycloakMiddleware
