import type { AuthDriver } from '@/auth'
import { User } from '@/models'
import { keycloakInitOptions } from '@/plugins/keycloak'
import { useAuthStore } from '@/stores/auth'
import { useTokenStore } from '@/stores/token'

const KeycloakDriver: AuthDriver = {
  refresh(): Promise<void> {
    console.log('refresh')
  },

  async login(): Promise<void> {
    console.log('login')
  },

  async proxyLogin(): Promise<void> {
    console.log('login')
  },

  async logout(): Promise<void> {
    console.log('logout')
  },

  async session(): Promise<User | null> {
    const token = useTokenStore()
    const auth = useAuthStore()

    const keycloak = token.getClient()
    auth.setLoading(true)
    auth.setInitalized(true)

    if (!keycloak.authenticated) {
      const isReady = await keycloak.init(keycloakInitOptions)

      if (!isReady) {
        await keycloak.login()
      }

      setInterval(async () => {
        await keycloak.updateToken(120)
        token.setAccess(keycloak.token || undefined)
        token.setRefresh(keycloak.refreshToken || undefined)
      }, 30_000) // try every 30 seconds

      token.setAccess(keycloak.token || undefined)
      token.setRefresh(keycloak.refreshToken || undefined)

      const user = await User.current()
      if (user) {
        auth.setUser(user)
        keycloak.authenticated = true
      }
    }

    auth.setLoading(false)
    return auth.getUser()
  },
}

export default KeycloakDriver
