<script setup lang="ts">
import { getQueryParameter } from '@atabix/core'
import { AButton, type Testable } from '@atabix/vue-ui'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import type { OAuth2TokenResponse } from '@/auth/TokenDriver'
import { OAuth2TokenError } from '@/auth/TokenDriver'
import TokenDriver from '@/auth/TokenDriver'
import type { SettingIdentity } from '@/models/Setting'
import { getCurrentLocale } from '@/plugins/i18n'
import { useAuthStore, useTokenStore } from '@/stores'
import { useSettingsStore } from '@/stores/settings'

export type LoginIdentitiesProperties = Testable

interface LoginIdentitiesEvents {
  /**
   * Emitted when the button is clicked
   */
  (event: 'error', value: OAuth2TokenError): void
  (event: 'success'): void
  (event: 'loading', value: boolean): void
  (event: 'logging', value: boolean): void
}

const emit = defineEmits<LoginIdentitiesEvents>()

const settings = useSettingsStore()
const auth = useAuthStore()

const isAutomaticLogin = computed<boolean>(() => (settings.get('is_integration_automatic') && !auth.hasLoggedOut) || false)

const isLoading = ref<boolean>(false)
const router = useRouter()

const identities = computed<SettingIdentity[]>(() => settings.get('identities') || [])

async function onClickIdentity(identity: SettingIdentity) {
  const response = await fetch(identity.url, {
    method: 'POST',
  })

  useTokenStore().setRedirect(router.currentRoute.value.fullPath)
  const redirectUrl = await response.json()
  globalThis.location.replace(redirectUrl)
}

onMounted(initialize)

async function initialize() {
  const code = getQueryParameter('code', true)

  if (code) {
    await login(code)
  } else if (isAutomaticLogin.value && identities.value.length === 1) {
    const identity = identities.value[0]
    if (identity) await onClickIdentity(identity)
  }
}

async function login(code: string): Promise<void> {
  if (isLoading.value) return
  setLoading(true)
  setIsLoggingIn(true)

  try {
    const response = await fetch(TokenDriver.url('/customers/token'), {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        'Accept-Language': getCurrentLocale(),
      }),
      body: JSON.stringify({
        grant_type: 'atabase_grant',
        code,
      }),
    })

    const json = await response.json()

    if (!response.ok) throw new OAuth2TokenError(json.error, json.error_description, json.message)
    const data: OAuth2TokenResponse = json

    await TokenDriver.consumeTokenResponse(data)
    await TokenDriver.session()
    useTokenStore().useRedirect()
    emit('success')
  } catch (error) {
    emit('error', error as OAuth2TokenError)
  } finally {
    setLoading(false)
  }
}

function setLoading(value: boolean) {
  isLoading.value = value
  emit('loading', value)
}

function setIsLoggingIn(value: boolean) {
  emit('logging', value)
}

onBeforeUnmount(() => {
  setLoading(false)
})
</script>

<template>
  <div
    :class="[
      'login-identities__container',
      { 'flex-row': identities.some((identity) => identity.image), 'flex-col': !identities.some((identity) => identity.image) },
    ]"
    :data-test
  >
    <template v-for="identity in identities" :key="identity.id">
      <AButton
        v-if="!identity.image"
        :id="identity.id"
        class="integration-button"
        :data-test="`login-identities-button:${identity.name}`"
        variant="outline"
        @click="() => onClickIdentity(identity)"
      >
        {{ identity.name }}
      </AButton>
      <button
        v-if="identity.image"
        :id="identity.id"
        class="integration-image"
        :data-test="`login-identities-button:${identity.name}`"
        :title="identity.name"
        @click="() => onClickIdentity(identity)"
      >
        <img :alt="identity.name" :src="identity.image" />
      </button>
    </template>
  </div>
</template>

<style scoped>
.login-identities__container {
  @apply flex;
  @apply flex-wrap;
  @apply items-center;
  @apply justify-center;
  @apply gap-4;
}

.integration-button {
  @apply w-full select-none;
}

.integration-image {
  @apply w-fit h-12 cursor-pointer select-none;

  img {
    @apply w-full h-full;
    @apply rounded-md;
  }
}
</style>
