export enum PageEnum {
  DASHBOARD = 'ce7ad177-0d77-4386-bdf9-03f251802ca9',
  CASES_BUSY = '3b859257-0fa1-4b06-8260-1d3362408297',
  CASES_FINISHED = 'c0aff01a-ff88-4000-9331-0326a1136af0',
  FORMS = 'ec68c520-bdac-4021-9472-5e5e368a5d94',
  FORMS_DRAFT_SUBMISSIONS = '568a14c2-0065-4be8-889f-b3d8d9b818e9',
  MY_DETAILS = 'a8e04390-f181-45f8-89fa-7ae2c98dd43f',
  FAQ = '4889e7eb-20e4-48f3-82d6-83368b759d96',
  MESSAGES = '6d89f76d-7913-4f88-9a30-072284000c45',
}
